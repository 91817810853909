


















































import { api } from "@/api/api";
import { ApiGetCustomerDto, ApiOrderTemplateDto } from "@/api/generated/Api";
import BaseModal from "@/components/shared/BaseModal.vue";
import BaseConfirmModalForm from "@/components/shared/modal/BaseConfirmModalForm.vue";
import { getInitialModalData, useOpenModal } from "@/fragments/modal/useOpenModal";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { deepCloneObject } from "@/shared/helpers/deepCloneHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { generateUuid } from "@/shared/helpers/uuidHelpers";
import { ModalBaseData } from "@/shared/interfaces/ModalBaseData.interface";
import { computed, defineComponent, onMounted, ref } from "@vue/composition-api";
import NewOrderOverviewModal from "./NewOrderOverviewModal.vue";
import { newOrderProps } from "./newOrderProps";

export default defineComponent({
  name: "NewOrderOverview",
  props: {
    ...newOrderProps,
  },
  components: { NewOrderOverviewModal, BaseModal, BaseConfirmModalForm },
  emits: ["orderChange"],
  setup(props, { emit }) {
    const modalData = ref(getInitialModalData());
    const deleteModalData = ref(getInitialModalData());
    const customerOrganizations = ref<ApiGetCustomerDto[]>();
    const customerPersons = ref<ApiGetCustomerDto[]>();

    const orderValues = computed(() => {
      if (!props.order) {
        return;
      }
      return deepCloneObject(props.order.map((singleOrder) => ({ ...singleOrder, temporaryId: generateUuid() })));
    });

    const headers = [
      { text: "Deltakerreferanse", value: "theirRef" },
      { text: "Bestillers navn", value: "participant.name" },
      { text: "Bestillers org nr", value: "participant.orgNo" },
      { text: "Bestillers org nr", value: "invoiceRecipient.name" },
      { text: "Fakturamottakers org nr", value: "invoiceRecipient.orgNo" },
      { text: "Antall ordrelinjer", value: "orderLines" },
      { text: "Informasjon", value: "actions" },
    ];

    const displayOrder = useOpenModal(ModalType.Edit, "", modalData);
    const displayDeleteOrderModal = useOpenModal(ModalType.Delete, "artikkel", deleteModalData);

    const deleteOrder = (modalData: ModalBaseData) => {
      const remove = orderValues.value?.filter((order) => order.temporaryId !== modalData.existingItem.temporaryId);
      emit("orderChange", remove);
      deleteModalData.value.showModal = false;
    };

    const editOrder = (order: ApiOrderTemplateDto[]) => {
      emit("orderChange", order);
      modalData.value.showModal = false;
    };

    const emitChanges = () => {
      emit("orderChange", orderValues.value);
    };

    onMounted(() => {
      globalLoadingWrapper({ blocking: true }, async () => {
        [customerOrganizations.value, customerPersons.value] = await Promise.all([
          (await api.customer.getCustomerOrganizationsAsync()).data,
          (await api.customer.getCustomerPersonsAsync()).data,
        ]);
      });
    });

    return {
      emitChanges,
      displayOrder,
      editOrder,
      displayDeleteOrderModal,
      deleteOrder,
      deleteModalData,
      customerOrganizations,
      customerPersons,
      orderValues,
      headers,
      modalData,
    };
  },
});







































































import { api } from "@/api/api";
import { ApiGetOrderDto } from "@/api/generated/Api";
import { useAutoDeepCloneProp } from "@/fragments/course/useAutoDeepCloneProp";
import { uniqueArray } from "@/shared/helpers/arrayHelpers";
import { isVocationalSchool } from "@/shared/helpers/curriculumHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { capitalize } from "@/shared/helpers/textHelpers";
import { getValidatableRef } from "@/shared/helpers/typeHelpers";
import { validateArrayNotEmpty, validateNotEmpty } from "@/shared/helpers/validationHelpers";
import { useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent, onMounted, ref } from "@vue/composition-api";
import { newOrderProps } from "./newOrderProps";

enum PreviousOrder {
  Yes = "yes",
  No = "no",
  DisplayAll = "displayAll",
}

export default defineComponent({
  name: "NewParticipantOrder",
  components: {},
  props: {
    ...newOrderProps,
  },
  emits: ["draftChange", "validate"],
  setup(props, { emit, refs }) {
    const store = useStore<StoreState>();
    const isLoadingParticipantStatus = ref(false);
    const draftValues = useAutoDeepCloneProp(props, "orderDraft");
    const courseParticipants = useAutoDeepCloneProp(props, "courseParticipantStudents");
    const courseParticipantStatus = ref("alle");
    const courseParticipantStatuses = ref(["alle"]);
    const existingOrderOnArticle = ref(PreviousOrder.No);

    const existingOrders = ref<ApiGetOrderDto[]>([]);

    const localIsVocationalSchool = computed(() => isVocationalSchool(store.state.plans.studyplan.mainCourseId));

    const getParticipantStatuses = async () => {
      if (courseParticipantStatuses.value.length > 1) {
        return;
      }
      isLoadingParticipantStatus.value = true;

      const courseDefaultOptions = (await api.course.getCourseDefaultOptions()).data;
      if (!courseDefaultOptions.participantStatuses || !courseDefaultOptions.vocationalSchoolParticipantStatuses) {
        return;
      }

      courseParticipantStatuses.value = [
        ...courseParticipantStatuses.value,
        ...(localIsVocationalSchool
          ? courseDefaultOptions.vocationalSchoolParticipantStatuses
          : courseDefaultOptions.participantStatuses),
      ];

      isLoadingParticipantStatus.value = false;
    };

    const getUsersWithExistingOrders = () =>
      uniqueArray(
        existingOrders.value
          .map((order) => {
            if (!order.orderLines) {
              return;
            }
            return order.orderLines;
          })
          .flat()
          .filter(
            (orderLine) =>
              !!orderLine?.articleNo &&
              !!draftValues.value.articles &&
              draftValues.value.articles.filter((article) => article.articleNo === orderLine.articleNo)
          )
          .map((orderLine) => orderLine?.user?.id)
      );

    const filterCourseParticipants = ({
      courseParticipantStatus,
      existingOrderOnArticle,
    }: {
      courseParticipantStatus?: string;
      existingOrderOnArticle?: string;
    }) => {
      const usersWithExistingOrders = getUsersWithExistingOrders();

      switch (existingOrderOnArticle) {
        case PreviousOrder.Yes:
          courseParticipants.value = props.courseParticipantStudents.filter((participant) =>
            usersWithExistingOrders.includes(participant.userId)
          );
          break;
        case PreviousOrder.No:
          courseParticipants.value = props.courseParticipantStudents.filter(
            (participant) => !usersWithExistingOrders.includes(participant.userId)
          );
          break;
        case PreviousOrder.DisplayAll:
          courseParticipants.value = props.courseParticipantStudents;
          break;
        default:
          break;
      }

      const selectedStudents = props.courseParticipantStudents.filter((cp) =>
        draftValues.value.userIds?.includes(cp.userId)
      );
      courseParticipants.value = [...selectedStudents, ...courseParticipants.value];

      if (!courseParticipantStatus || courseParticipantStatus === "alle") {
        return;
      }
      courseParticipants.value = courseParticipants.value.filter(
        (participant) => participant.status === courseParticipantStatus
      );
    };

    const updateParticipantList = () => {
      filterCourseParticipants({
        courseParticipantStatus: courseParticipantStatus.value,
        existingOrderOnArticle: existingOrderOnArticle.value,
      });
    };

    const allUsersSelected = computed(
      () => draftValues.value.userIds?.length === props.courseParticipantStudents.length
    );
    const someUsersSelected = computed(
      () => draftValues.value.userIds && draftValues.value.userIds.length > 0 && !allUsersSelected.value
    );

    const selectAllIcon = computed(() => {
      if (allUsersSelected.value) {
        return "mdi-close-box";
      }
      if (someUsersSelected.value) {
        return "mdi-minus-box";
      }
      return "mdi-checkbox-blank-outline";
    });

    const toggleUserIds = () => {
      if (allUsersSelected.value || someUsersSelected.value) {
        draftValues.value.userIds = [];
        emitChanges();
        return;
      }
      draftValues.value.userIds = courseParticipants.value.map((currentStudent) => currentStudent.userId);
      emitChanges();
    };

    const updateUserIds = () => {
      draftValues;
    };

    const validate = () => {
      const isValid = !!getValidatableRef(refs.participantForm)?.validate();
      emit("validate", isValid);
    };

    onMounted(() => {
      globalLoadingWrapper({}, async () => {
        existingOrders.value = (await api.course.getOrdersAsync(props.courseId)).data;
      });
    });

    const emitChanges = () => {
      validate();
      emit("draftChange", draftValues.value);
    };

    return {
      draftValues,
      courseParticipantStatus,
      validateArrayNotEmpty,
      selectAllIcon,
      validateNotEmpty,
      courseParticipants,
      existingOrderOnArticle,
      courseParticipantStatuses,
      isLoadingParticipantStatus,
      PreviousOrder,
      toggleUserIds,
      updateUserIds,
      capitalize,
      emitChanges,
      getParticipantStatuses,
      updateParticipantList,
      validate,
    };
  },
});









































import { ApiGetCustomerDto, ApiOrderLineDto } from "@/api/generated/Api";
import CourseSingleOrderTable from "@/components/course/economy/CourseSingleOrderTable.vue";
import SingleOrderDetailValues from "@/components/course/economy/SingleOrderDetailValues.vue";
import BaseLayout from "@/components/shared/BaseLayout.vue";
import { useAutoDeepCloneProp } from "@/fragments/course/useAutoDeepCloneProp";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { formatDate } from "@/shared/helpers/dateHelpers";
import { deepCloneObject } from "@/shared/helpers/deepCloneHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import { generateUuid } from "@/shared/helpers/uuidHelpers";
import { NewOrderDraftWithUUID, OrderLineWithUUID } from "@/shared/interfaces/OrderWithUIID.interface";
import { useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent, PropType, ref } from "@vue/composition-api";

export default defineComponent({
  name: "NewOrderOverviewModal",
  components: {
    CourseSingleOrderTable,
    SingleOrderDetailValues,
    BaseLayout,
  },
  props: {
    order: {
      type: Object as PropType<NewOrderDraftWithUUID>,
      required: true,
    },
    orders: {
      type: Array as PropType<NewOrderDraftWithUUID[]>,
      required: true,
    },
    customerPersons: {
      type: Array as PropType<ApiGetCustomerDto[]>,
      required: true,
    },
    customerOrganizations: {
      type: Array as PropType<ApiGetCustomerDto[]>,
      required: true,
    },
  },
  emits: ["close", "submit"],
  setup(props, { emit }) {
    const store = useStore<StoreState>();
    const isEditing = ref(false);
    const singleOrder = useAutoDeepCloneProp<NewOrderDraftWithUUID>(props, "order");
    const articleNos = computed(() => store.state.courses.course.economy?.articleNos);

    const updateOrderValues = (updatedOrderValues: NewOrderDraftWithUUID) => {
      openNotification(store, NotificationItemType.Success, "Ordreutkast oppdatert");
      singleOrder.value = updatedOrderValues;
      isEditing.value = false;
    };

    const updateOrderLines = (orderLines: OrderLineWithUUID[]) => {
      if (!singleOrder.value) {
        return;
      }
      openNotification(store, NotificationItemType.Success, "Ordrelinjer oppdatert");
      singleOrder.value = { ...singleOrder.value, orderLines };
    };

    const onSubmit = () => {
      const ordersWithUUID = props.orders.map((mappedOrder) => {
        if (mappedOrder.temporaryId !== singleOrder.value.temporaryId) {
          return mappedOrder;
        }
        return { ...singleOrder.value };
      });

      emit("submit", ordersWithUUID, () => emit("close"));
    };

    const generateUUIDForOrderlines = (orderLines: ApiOrderLineDto[]) =>
      deepCloneObject(orderLines.map((orderLine) => ({ ...orderLine, temporaryId: generateUuid() })));

    const orderLinesWithUUID = computed(() => {
      if (!singleOrder.value?.orderLines) {
        return;
      }
      return generateUUIDForOrderlines(singleOrder.value.orderLines);
    });
    const orderValuesWithUUID = computed(() => {
      if (!singleOrder.value) {
        return;
      }
      return deepCloneObject({ ...singleOrder.value, orderLines: orderLinesWithUUID.value });
    });

    return {
      articleNos,
      isEditing,
      singleOrder,
      formatDate,
      updateOrderValues,
      updateOrderLines,
      onSubmit,
      orderValuesWithUUID,
      orderLinesWithUUID,
    };
  },
});

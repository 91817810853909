





























































import { api } from "@/api/api";
import { ApiArticleDto } from "@/api/generated/Api";
import { useAutoDeepCloneProp } from "@/fragments/course/useAutoDeepCloneProp";
import { getArticlesByArticleNos, inInvalidArticleInterval } from "@/shared/helpers/courseOrderHelpers";
import { formatCurrency } from "@/shared/helpers/formattingHelpers";
import { getValidatableRef } from "@/shared/helpers/typeHelpers";
import { validateArrayNotEmpty, validateBoolean } from "@/shared/helpers/validationHelpers";
import { useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent, onMounted, ref } from "@vue/composition-api";
import { newOrderProps } from "./newOrderProps";

export default defineComponent({
  name: "NewOrderDraftForm",
  props: {
    ...newOrderProps,
  },
  emits: ["draftChange", "validate"],
  setup(props, { emit, refs }) {
    const store = useStore<StoreState>();
    const isLoadingArticles = ref(false);
    const draftValues = useAutoDeepCloneProp(props, "orderDraft");
    const coursePrice = computed(() => store.state.courses.course.economy?.price);

    const filteredArticleCostList = ref<ApiArticleDto[]>();

    const articleValue = (article: ApiArticleDto) => article;

    const validate = () => {
      const isValid = !!getValidatableRef(refs.form)?.validate();
      emit("validate", isValid);
    };

    const emitChanges = () => {
      emit("draftChange", draftValues.value);
    };

    onMounted(async () => {
      isLoadingArticles.value = true;
      const economyDefaultOptions = (await api.economy.getCourseEconomyBaseAsync()).data;
      if (!economyDefaultOptions.articles) {
        return;
      }
      const articles = getArticlesByArticleNos(
        props.course.economy?.articleNos ?? undefined,
        economyDefaultOptions.articles
      );

      filteredArticleCostList.value = articles.map((article) => ({
        articleNo: article.id,
        name: article.name,
        ...(!inInvalidArticleInterval(article.id) && { amountOverride: 0 }),
      }));
      isLoadingArticles.value = false;
    });

    return {
      coursePrice,
      validateBoolean,
      draftValues,
      articleValue,
      emitChanges,
      filteredArticleCostList,
      validateArrayNotEmpty,
      isLoadingArticles,
      validate,
      headers,
      formatCurrency,
    };
  },
});

const headers = [
  { text: "Artikkel nr", value: "articleNo" },
  { text: "Navn", value: "name" },
  { text: "Original pris", value: "amountOverride" },
  { text: "Overskriv pris", value: "overrideAmount" },
];

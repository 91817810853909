







import { ApiGetCourseDto, ApiGetCourseParticipantDto } from "@/api/generated/Api";
import NewOrderStepper from "@/components/course/economy/NewOrderStepper.vue";
import BaseLayout from "@/components/shared/BaseLayout.vue";
import { useRestrictedAccessApi } from "@/shared/helpers/accessLevelApiAdapter";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { validateBoolean } from "@/shared/helpers/validationHelpers";
import { useRoute } from "@/shared/useHelpers";
import { defineComponent, onMounted, ref } from "@vue/composition-api";

export default defineComponent({
  components: { BaseLayout, NewOrderStepper },
  name: "OrderDraftPage",
  setup() {
    const route = useRoute();
    const courseId = +route.params.id;
    const restrictedAccessApi = useRestrictedAccessApi();
    const course = ref<ApiGetCourseDto>();
    const courseParticipantStudents = ref<ApiGetCourseParticipantDto[]>([]);

    const isCompanyInternal = ref(false);

    onMounted(() => {
      globalLoadingWrapper({ blocking: true }, async () => {
        const [courseRequest, courseParticipantsRequest] = await Promise.all([
          restrictedAccessApi.getCourse(courseId),
          restrictedAccessApi.getCourseParticipants(courseId, { IsActive: true, RoleNames: ["Student"] }),
        ]);
        course.value = courseRequest.data;
        courseParticipantStudents.value = courseParticipantsRequest.data;
      });
    });

    return {
      isCompanyInternal,
      validateBoolean,
      course,
      courseParticipantStudents,
      courseId,
    };
  },
});

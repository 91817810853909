var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.deleteModalData.showModal)?_c('BaseModal',{attrs:{"maxWidth":400}},[_c('BaseConfirmModalForm',{attrs:{"modalBaseData":_vm.deleteModalData},on:{"close":function($event){_vm.deleteModalData.showModal = false},"submit":_vm.deleteSingleOrder}},[_vm._v(" Er du sikker på at du vil slette ordren? ")])],1):_vm._e(),_c('BaseTableFiltered',{attrs:{"headers":_vm.headers,"items":_vm.courseOrders,"search":_vm.search,"filter":_vm.filter,"sort-by":"id","sort-desc":true,"item-key":"id","show-select":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"title font-weight-light text-center",staticStyle:{"align-self":"center"},attrs:{"data-cy":"titleCourse"}},[_vm._v("Økonomi")]),_c('v-spacer'),_c('v-text-field',{attrs:{"data-cy":"searchOrderList","append-icon":"mdi-magnify","label":"Søk","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('BaseTooltipIconButton',{attrs:{"color":"primary","btnText":"Ny ordre","icon":"mdi-plus","dataCy":"newButton"},on:{"handleClick":_vm.newOrder}}),(_vm.selectedRows.length > 0)?_c('BaseTooltipIconButton',{attrs:{"color":"primary","btnText":"Send til godkjenning","icon":"mdi-send","dataCy":"sendButton"},on:{"handleClick":_vm.sendMultipleOrders}}):_vm._e(),(_vm.selectedRows.length > 0)?_c('BaseTooltipIconButton',{attrs:{"color":"primary","btnText":"Slett ordre","icon":"mdi-delete","dataCy":"deleteButton"},on:{"handleClick":_vm.deleteMultipleOrders}}):_vm._e()]},proxy:true},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatCurrency(_vm.calculateAmount(item.orderLines)))+" ")]}},{key:"item.inserted",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.erpSendt ? _vm.formatLocalizedDate(item.erpSendt) : _vm.formatLocalizedDate(item.inserted))+" ")]}},{key:"item.contraEntryOrderId",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value ? "Kreditordre" : "Ordre")+" ")]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"data-cy":"displayOrder"},on:{"click":function($event){return _vm.goToOrderDetails(item.id)}}},'v-icon',attrs,false),on),[_vm._v("mdi-open-in-new")])]}}],null,true)},[_c('span',[_vm._v("Vis ordre")])]),(_vm.checkOrderActionButtonAvailable(item.status.id, _vm.CourseOrderButtonValues.DeleteAvailable))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"data-cy":"deleteOrder"},on:{"click":function($event){return _vm.showDeleteOrderModal(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-delete")])]}}],null,true)},[_c('span',[_vm._v("Slett ordre")])]):_vm._e()]}}],null,true),model:{value:(_vm.selectedRows),callback:function ($$v) {_vm.selectedRows=$$v},expression:"selectedRows"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }






















































































import { api } from "@/api/api";
import {
  ApiArticleDtoType,
  ApiGetApplicationFormTemplateDto,
  ApiGetCourseDto,
  ApiGetCourseParticipantDto,
  ApiGetCustomerDto,
  ApiGetOrderDto,
  ApiOrderLineDto,
} from "@/api/generated/Api";
import BaseModal from "@/components/shared/BaseModal.vue";
import BaseTooltipIconButton from "@/components/shared/button/BaseTooltipIconButton.vue";
import BaseConfirmModalForm from "@/components/shared/modal/BaseConfirmModalForm.vue";
import BaseTableFiltered from "@/components/shared/table/BaseTableFiltered.vue";
import { getInitialModalData, useOpenModal } from "@/fragments/modal/useOpenModal";
import { CourseOrderButtonValues } from "@/shared/enums/courseOrderButtonValues.enum";
import { CourseOrderStatus } from "@/shared/enums/courseOrderStatusEnum";
import { LoadingType } from "@/shared/enums/loading-type.enum";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { SingleCourseRouteNames } from "@/shared/enums/RouteNames/singleCourseRouteNames.enum";
import {
  calculateTotalSum,
  checkOrderActionButtonAvailable,
  deleteOrder,
  getArticlesByArticleNos,
  queueOrder,
} from "@/shared/helpers/courseOrderHelpers";
import { formatLocalizedDate } from "@/shared/helpers/dateHelpers";
import { formatCurrency } from "@/shared/helpers/formattingHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { ModalBaseData } from "@/shared/interfaces/ModalBaseData.interface";
import { useRoute, useRouter, useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent, onMounted, PropType, ref } from "@vue/composition-api";
import { VSwitch } from "vuetify/lib";

export default defineComponent({
  name: "CourseOrderTable",
  components: { BaseTableFiltered, BaseTooltipIconButton, BaseModal, BaseConfirmModalForm },
  props: {
    courseParticipantStudents: {
      type: Array as PropType<ApiGetCourseParticipantDto[]>,
      required: true,
    },
    course: {
      type: Object as PropType<ApiGetCourseDto>,
      required: true,
    },
  },
  setup(props) {
    const route = useRoute();
    const router = useRouter();
    const store = useStore<StoreState>();
    const courseOrders = ref<ApiGetOrderDto[]>();
    const articleCostList = ref<ApiArticleDtoType[]>();
    const filteredArticleCostList = ref<ApiArticleDtoType[]>();
    const customerOrganizationList = ref<ApiGetCustomerDto[]>();
    const selectedRows = ref<ApiGetOrderDto[]>([]);
    const applicationFormTemplate = ref<ApiGetApplicationFormTemplateDto>();
    const search = ref("");

    const deleteModalData = ref(getInitialModalData());

    onMounted(async () => {
      loadOrders();
      await globalLoadingWrapper({ type: LoadingType.SkeletonTable }, async () => {
        const [economyDefaultOptions, customerOrganizations] = await Promise.all([
          api.economy.getCourseEconomyBaseAsync(),
          api.customer.getCustomerOrganizationsAsync(),
          getApplicationFormTemplate(),
        ]);
        if (!economyDefaultOptions.data.articles) {
          return;
        }
        articleCostList.value = economyDefaultOptions.data.articles;
        customerOrganizationList.value = customerOrganizations.data;
      });
      filteredArticleCostList.value = getArticlesByArticleNos(
        props.course.economy?.articleNos ?? undefined,
        articleCostList.value
      );
    });

    const showDeleteOrderModal = useOpenModal(ModalType.Delete, "ordreutkast", deleteModalData);

    const sendMultipleOrders = async () => {
      await globalLoadingWrapper({ blocking: true }, async () => {
        if (!selectedRows.value) {
          return;
        }
        await Promise.all(
          selectedRows.value.map(async (currentOrder) => {
            await queueOrder(store, currentOrder.id);
          })
        );
      });
      selectedRows.value = [];
      await loadOrders();
    };

    const deleteSingleOrder = async (modalData: ModalBaseData) => {
      const orderId = modalData.existingItemId;
      await globalLoadingWrapper({ blocking: true }, async () => {
        await deleteOrder(store, orderId);
        deleteModalData.value.showModal = false;
      });
      await loadOrders();
    };

    const deleteMultipleOrders = async () => {
      await globalLoadingWrapper({ blocking: true }, async () => {
        if (!selectedRows.value) {
          return;
        }
        await Promise.all(selectedRows.value.map((currentOrder) => deleteOrder(store, currentOrder.id)));
        selectedRows.value = [];
      });
      await loadOrders();
    };

    const calculateAmount = (orderLines: ApiOrderLineDto[]) => {
      const initialValue = 0;
      return orderLines.reduce(
        (previousValue, currentOrderLine) => previousValue + calculateTotalSum(currentOrderLine),
        initialValue
      );
    };

    const loadOrders = async () => {
      await globalLoadingWrapper({ type: LoadingType.SkeletonTable }, async () => {
        const courseOrderList = (await api.course.getOrdersAsync(+route.params.id)).data;
        courseOrders.value = courseOrderList.map((order) => ({
          ...order,
          isSelectable: order.status?.id === CourseOrderStatus.DraftValue,
        }));
      });
    };

    const getApplicationFormTemplate = async () => {
      await globalLoadingWrapper({ type: LoadingType.SkeletonTable }, async () => {
        if (!props.course.webDescription?.templateId) {
          return;
        }
        applicationFormTemplate.value = (
          await api.appformtemplate.getAppFormTemplateAsync(props.course.webDescription.templateId)
        ).data;
      });
    };

    const goToOrderDetails = (orderId: string) =>
      router.push({
        name: SingleCourseRouteNames.CourseEconomyDetails,
        params: {
          id: route.params.id,
          orderNo: orderId,
        },
      });

    return {
      deleteModalData,
      headers,
      courseOrders,
      articleCostList,
      customerOrganizationList,
      selectedRows,
      search,
      filter,
      filteredArticleCostList,
      CourseOrderButtonValues,
      applicationFormTemplate,
      formatCurrency,
      calculateAmount,
      formatLocalizedDate,
      loadOrders,
      sendMultipleOrders,
      deleteMultipleOrders,
      deleteSingleOrder,
      showDeleteOrderModal,
      goToOrderDetails,
      checkOrderActionButtonAvailable,
      newOrder: () =>
        router.push({
          name: SingleCourseRouteNames.CourseEconomyNew,
          params: { id: route.params.id },
        }),
    };
  },
});

const filter = computed(() => [
  {
    component: VSwitch,
    value: "status",
    staticClass: "mx-3",
    default: false,
    attrs: {
      label: "Vis avbrutte",
      inset: true,
    },
    apply: (value: { id: number; name: string }, model: boolean) => {
      const display = value.id !== CourseOrderStatus.CanceledValue;
      return model || display;
    },
  },
]);

const headers = [
  { text: "Ordre id", value: "id" },
  { text: "Ordrestatus", value: "status.name" },
  { text: "Ordretype", value: "contraEntryOrderId" },
  { text: "Ordredato", value: "inserted" },
  { text: "Bestiller", value: "participant.name" },
  { text: "Fakturamottaker", value: "invoiceRecipient.name" },
  { text: "Beløp", value: "amount" },
  { text: "Betalingsstatus", value: "erpStatus" },
  { text: "Handlinger", value: "actions" },
];
